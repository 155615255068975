#projects {
  padding-top: 5vw;
  margin-bottom: 5vw;
  margin-left: 10vw;
  margin-right: 10vw;
}

#projects h1 {
  font-size: 3em;
  font-weight: bold;
}

#projects hr {
  margin-top: 30px;
  margin-bottom: 30px;
}

.projects_container {
  /* display: grid;
  grid-template-columns: repeat(1fr 1fr);
  grid-template-rows: repeat(30vw); */
  display: block;
  box-sizing: border-box;
}

.projects_row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.projects_row li {
  display: block;
  width: 40vw;
}

@media only screen and (max-width: 767px) {
  #projects {
    padding-top: 25px;
  }

  #projects h1 {
  font-size: 30px;
  text-align: center;
}

#projects hr {
  margin-top: 10px;
  margin-bottom: 20px;
}
.projects_row {
  display: block;
  width: 100%;
}
.projects_row li {
  width: 100%;
}
}
