#footer {
  background: black;
  height: 40vh;
  color: white;
  font-size: 1vw;
}

.footer_container {
  height: inherit;
  padding-left: 10vw;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.footer_info {
  line-height: 1.7vw;
  word-spacing: 2px;
}

.footer_info a {
  text-decoration: none;
  color: lightblue;
}

.footer_info a:hover {
  font-weight: bold;
  text-decoration: underline;
}

.footer_socials {
  display: flex;
}

.footer_socials li {
  margin-right: 1vw;
  padding: 8px;
  border: 1px solid white;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  line-height: 8px;
}

.footer_socials li a {
  text-decoration: none;
  color: white;
}

.footer_socials li:hover {
  background-color: lightblue;
}

.footer_container h3 {
  font-family: "Marck Script", cursive;
  font-size: 2vw;
}

@media only screen and (max-width: 767px) {
  #footer {
    margin-top: 60px;
    font-size: 13px;
    height: 200px;
  }

  .footer_container h3 {
    font-family: "Marck Script", cursive;
    font-size: 20px;
  }

  .footer_info {
    line-height: 1.3;
    word-spacing: 2px;
  }

  .footer_container {
    padding-bottom: 0;
  }

  .footer_socials li {
    margin-right: 10px;
    padding: 3px;
    line-height: 3px;
  }
}
