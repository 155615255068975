* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    font-family: "Open Sans",sans-serif;
    scroll-behavior: smooth;
    
    /* hide horizontal scrollbar */
    /* overflow-x: hidden;  */
}