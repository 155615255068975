.container {
  height: 100vh;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain; */
  position: relative;
  --animationDuration: 1.5s; /* css variable */
}

#background_img {
  position: absolute;
  z-index: -1;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.inner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding-top: 23vh; 
  padding-left: 10vw; */
  /* width: 30vw;
  height: 46vh;
  object-fit: contain; */
}

#headshot {
  display: block;
  margin-top: -10vh;
  width: 20vw;
  margin-left: 15vw;
  margin-right: 5vw;
}

.text-container {
  display: block;
  color: #fff;
  margin-top: -15vh;
  margin-right: 10vw;
}

.text-container h5 {
  font-size: 3vw;
  font-weight: 1em;
  animation-delay: 0.5s;
}
.text-container h2 {
  font-size: 4vw;
  font-weight: 2em;
  animation-delay: 1s;
  margin-top: 2vw;
}

.text-container p {
  font-size: 2vw;
  animation-delay: 2s;
  margin-top: 2vw;
}

.download-btn {
  border: 1px solid #fff;
  border-radius: 5px;
  color: #333;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 1vw;
  font-weight: 700;
  margin-top: 2.5vw;
  overflow: hidden;
  padding: 1vw 2vw;
  position: relative;
  background: #fff;
  animation-delay: 2.5s;
  text-decoration: none; /* get rid of underline */
  transition: all 0.3s ease-in-out;
}

.download-btn:hover {
  background: lightblue;
}

.text-container h5,
.text-container h2,
.text-container p,
.download-btn,
#headshot {
  animation-duration: var(--animationDuration);
  animation-name: fadeIn;
  animation-timing-function: ease-in;
  opacity: 0;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    height: 600px;
  }
  .inner-container {
    flex-direction: column;
  }
  #headshot {
    width: 180px;
    margin: 0;
    margin-top: 50px;
  }

  .text-container {
    color: #fff;
    margin: 0;
    text-align: center;
    margin-bottom: 100px;
  }

  .text-container h5 {
    font-size: 25px;
    font-weight: 1em;
    animation-delay: 0.5s;
  }
  .text-container h2 {
    font-size: 30px;
    font-weight: 2em;
    animation-delay: 1s;
    margin-top: 2vw;
  }

  .text-container p {
    font-size: 15px;
    animation-delay: 2s;
    margin-top: 2vw;
  }

  .download-btn {
    border-radius: 5px;
    font-size: 8px;
    font-weight: 700;
    margin-top: 2.5vw;
    overflow: hidden;
    padding: 5px 5px;
  }
}
