.projectItem-container {
  box-sizing: border-box;
  box-shadow: 0 0 9px 1px rgb(17 17 17 / 30%);
  padding: 0.5vw; /* inside each box */
  margin: 1vw; /* outside each box */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 38vw;
  height: 30vw;
}

.projectItem-img-desc {
  position: relative;
  height: 70%;
  margin-bottom: 0.5vw;
}

.projectItem-container img {
  position: absolute;
  object-fit: cover;
  z-index: -1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.projectItem-desc {
  position: absolute;
  background-color: white;
  width: 100%;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  padding: 1vw;
  font-size: 1vw;
}

.projectItem-desc p {
  line-height: 1.8;
}

.projectItem-container:hover .projectItem-desc {
  opacity: 1;
}

.projectItem-brief {
  text-align: center;
  color: #616161;
  font-family: "Open Sans", sans-serif;
  transition: all 0.5s ease 0s;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 1px;
  padding-left: 2px;
  padding-right: 2px;
}

.projectItem-brief p {
  margin-bottom: 10px;
  line-height: 1.3;
  word-spacing: 0.1em;
}

.projectItem-brief:hover .projectItem-brief:active {
  box-shadow: 0px 6px 15px 0px rgba(59, 59, 59, 0.21);
}

.projectItem-brief-title {
  font-size: 2vw;
  text-transform: capitalize;
  font-weight: 700;
  cursor: pointer;
  color: black;
}

.projectItem-brief-desc {
  font-size: 1vw;
  color: #616161;
}

.links {
  position: absolute;
  top: 60%;
  right: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 4vw;
  height: 7.5vw;
}

.link_btn {
  box-shadow: 0 0 4px 1px rgb(17 17 17 / 40%);
  font-size: 1vw;
  background: white;
  text-decoration: none;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  text-align: center;
  line-height: 3vw;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  overflow: hidden;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link_btn:hover {
  background-color: lightblue;
}

@media only screen and (max-width: 767px) {
  .projectItem-container {
    margin-bottom: 20px; /* outside each box */
    padding: 5px;
    width: 100%;
    height: 55vw;
  }
  .projectItem-brief-title {
    font-size: 15px;
  }
  .projectItem-brief-desc {
    font-size: 10px;
  }

  .projectItem-brief p {
    margin-bottom: 5px;
    word-spacing: 0;
  }

  .links {
    position: absolute;
    top: 55%;
    right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 30px;
    height: 70px;
  }

  .link_btn {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
  }

  .MuiSvgIcon-root {
    font-size: 1rem;
  }

  .projectItem-desc {
    font-size: 10px;
  }
}
