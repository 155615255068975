#about {
  padding-top: 5vw;
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  justify-content: space-between;
}

#about h1 {
  font-size: 3em;
  font-weight: bolder;
}

#about img {
  object-fit: cover;
  width: 30vw;
  box-sizing: border-box;
  box-shadow: 0 0 9px 1px rgb(17 17 17 / 30%);
  border-radius: 10px;
}

.about-text-container {
  margin-left: 30px;
  box-sizing: border-box;
  padding: 10px 25px 0px 25px; /* top right bottom left */
}

.about-desc {
  line-height: 1.4;
}

.about-desc-bold {
  font-size: 20px;
  font-weight: bold;
  color: #666;
}

.about-desc-bulletpt {
  margin-top: 25px;
}

.about-desc-bulletpt li {
  margin-bottom: 15px;
  color: #666;
}

.about-desc-bulletpt span {
  text-decoration: underline;
}

#about hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  #about {
    display: block;
    margin-bottom: 15px;
    padding-top: 60px;
  }

  #about img {
    width: 100%;
    margin-top: 10px;
  }

  .about-text-container {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }

  #about h1 {
    font-size: 30px;
    text-align: center;
  }
  .about-desc-bold {
    font-size: 15px;
  }
  .about-desc-bulletpt {
    font-size: 13px;
  }
}
