* {
  box-sizing: border-box;
}

nav {
  display: flex; /* make nav into a flex box so we can use justify-content and align-items properties */
  justify-content: space-between; /* make div and ul apart */
  align-items: center; /* make them center vertically in the navbar flex box */
  background-color: black;
  color: white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.logo {
  font-family: "Marck Script", cursive;
  font-size: 25px;
  padding: 15px;
  margin-left: 40px;
}

.nav-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

li {
  list-style: none; /* get rid of bullet point */
}

nav a {
  text-decoration: none;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  color: white;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  margin-right: 40px;
}

nav a:hover {
  color: lightblue;
}

.nav-list a:hover {
  background-color: #555;
}

.hamburger {
  display: none;
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 17px;
}

@media only screen and (max-width: 767px) {
  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .hamburger {
    display: block;
  }

  .nav-list {
    display: none;
    width: 100%;
  }

  .nav-list li {
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  nav a {
    padding: 0;
    margin: 0;
  }

  .nav-list li:hover {
    background-color: #555;
  }

  .nav-list.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
